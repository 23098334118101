<template>
  <router-view />
  <Footer />
</template>

<style>
#app {
  font-family: "Epilogue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
}

@font-face {
  font-family: "fake";

  src: url(@/assets/fonts/fake-serif.ttf);
  src: url(@/assets/fonts/fake-serif.ttf) format("truetype"), url(@/assets/fonts/fake-serif.otf) format("opentype");
}
</style>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Footer,
  },
};
</script>
