<template>
  <section class="container mx-auto py-8 px-5 border-b border-black">
    <a href="/" class="w-14 h-10"><img class="w-14 h-10 object-fill bg-red-0" src="@/assets/img/logo-german.png" alt="logo German" /></a>

    <ul class="flex gap-4 lg:gap-8 flex-wrap mt-4 text-lg">
      <li>
        <a href="/">Home</a>
      </li>

      <li>
        <a href="/Proyectos">Proyectos</a>
      </li>

      <li>
        <a href="/Venta">Venta</a>
      </li>

      <li>
        <a href="/Arte-Terapia">Arte Terapia</a>
      </li>

      <li>
        <a href="/Solicitudes">Solicitudes</a>
      </li>

      <li>
        <a href="/Contacto">Contacto</a>
      </li>
    </ul>
  </section>

  <section class="flex justify-around py-5 items-center flex-col sm:flex-row px-5 gap-y-5 container mx-auto">
    <div>
      <a href="mailto:contacto@psiquiarte.com"><p>contacto@psiquiarte.com</p></a>
      <p>© Copyright 2022, Germán Valencia</p>
      <p>Todos los derechos reservados</p>
    </div>

    <div class="text-2xl">
      <div class="flex justify-center gap-6">
        <a href="https://www.facebook.com/german.a.franco.14" target="_blank" rel="noopener noreferrer">
          <i class="fa-brands fa-facebook fa-lg"></i>
        </a>

        <a href="https://www.instagram.com/german_._a._valencia._/" target="_blank" rel="noopener noreferrer">
          <i class="fa-brands fa-instagram fa-lg"></i>
        </a>
      </div>
    </div>
  </section>

  <section class="flex flex-col mx-auto md:flex-row space-y-3 md:space-y-0 md:space-x-5 place-content-center place-items-center py-4 sm:py-7">
    <h1 class="text-gray-300 text-lg font-normal">With Love:</h1>
    <a class="flex justify-center" href="https://softgreen.com.co/" target="_blank" rel="noopener noreferrer">
      <img class="h-12" src="@/assets/img/softgreen.png" alt="Logo Softgreen" />
    </a>
    <a class="flex justify-center" href="https://cide.org.co/" target="_blank" rel="noopener noreferrer">
      <img class="h-12" src="@/assets/img/cide.png" alt="Logo CIDE" />
    </a>
    <a class="flex justify-center" href="https://registra.com.co/" target="_blank" rel="noopener noreferrer">
      <img class="h-12" src="@/assets/img/registra.png" alt="Logo Registra" />
    </a>
  </section>
</template>
