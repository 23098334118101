<template>
  <nav class="sticky top-0 z-50 flex items-center justify-around flex-wrap p-3 bg-white border-b border-gray-300">
    <div>
      <a href="/"><img class="w-12 h-10 object-fill" src="@/assets/img/logo-german.png" alt="logo German" /></a>
    </div>

    <div class="block lg:hidden">
      <button id="burguer-button" @click="myFunctionClick()" class="flex items-center px-3 py-2 shadow-lg border-2 rounded">
        <svg class="fill-current h-5 w-6" viewBox="0 0 20 20">
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </button>
    </div>

    <div id="menu-list" class="w-full hidden lg:flex lg:w-auto py-2">
      <ul class="text-xl flex-col space-y-2 lg:space-y-0 lg:flex-grow lg:flex-row flex lg:space-x-10 mt-5 lg:mt-0 items-center">
        <li>
          <router-link to="/">Home</router-link>
        </li>

        <li>
          <router-link to="/Proyectos">Proyectos</router-link>
        </li>

        <li>
          <router-link to="/Venta">Venta</router-link>
        </li>

        <li>
          <router-link to="/Arte-Terapia">Arte Terapia</router-link>
        </li>

        <li>
          <router-link to="/Solicitudes">Solicitudes</router-link>
        </li>

        <li>
          <router-link to="/Contacto" class="bg-black text-white p-3">Contacto</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script scoped>
export default {
  methods: {
    myFunctionClick: function () {
      const menu = document.querySelector("#menu-list");
      if (menu) {
        menu.classList.toggle("hidden");
      }
    },
  },
};
</script>

<style>

nav a.router-link-exact-active {
  border-bottom-width: 1px;
  border-color: black;
}

</style>