import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/Proyectos',
    name: 'projects',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProjectsView.vue')
  },
  {
    path: '/Venta',
    name: 'sales',
    component: () => import(/* webpackChunkName: "about" */ '../views/SalesView.vue')
  },
  {
    path: '/Arte-Terapia',
    name: 'art-therapy',
    component: () => import(/* webpackChunkName: "about" */ '../views/ArtTherapyView.vue')
  },
  {
    path: '/Solicitudes',
    name: 'requests',
    component: () => import(/* webpackChunkName: "about" */ '../views/RequestsView.vue')
  },

  {
    path: '/Contacto',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "about" */ '../views/NotFoundView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

export default router
