<template>
 <Navbar />
  <section class="relative pt-56 pb-16 sm:py-36 lg:py-64 z-20 overflow-hidden">
    <div class="bg-gray-200 w-80 lg:w-160 h-80 lg:h-160 flex justify-center -rotate-12 absolute top-12 lg:top-20 right-0 sm:left-1/2 z-0">
      <img class="rotate-6 h-full w-full" src="@/assets/img/home-0.jpeg" alt="German" />
    </div>

    <div class="text-left ml-6 lg:ml-32 2xl:ml-64 z-20 relative">
      <h1 class="font-black text-4xl sm:text-6xl lg:text-8xl text-border">HOLA<br />SOY GERMAN</h1>
      <p class="font-fake-serif text-5xl sm:text-7xl lg:text-9xl pl-20 lg:pl-96 text-border">"Vive el Arte"</p>
    </div>
  </section>

  <section class="bg-black text-white py-14 lg:py-20">
    <div class="text-xl lg:text-5xl font-black flex justify-center items-center pb-10 lg:pb-12 space-x-3 lg:space-x-10">
      <h1 class="flex items-center">Hola soy Germán Valencia</h1>
      <div class="bg-white text-black py-3 px-6 text-sm lg:text-3xl flex">1</div>
    </div>
    <article class="grid lg:grid-cols-2 container mx-auto text-md md:text-xl 2xl:text-2xl">
      <swiper :navigation="true" :loop="true" :modules="modules" class="mySwiper">
        <swiper-slide class="bg-black">
          <section class="px-6 lg:px-12">
            <p>
              Mi vida ha transcurrido en Manizales (Colombia), sin embargo nací en Aguadas (Caldas), de donde son oriundas mis familias. Hijo único. No tengo recuerdos de donde nací porque sin cumplir los dos años de edad ya estaba en la ciudad. Mis padres
              trabajadores me fueron educando, mi primaria transcurrió en escuelas públicas, así como mi bachillerato, que en esa época también se consideraba colegio oficial. Mis mejores recuerdos del Seminario Menor. Aunque mi inclinación no fue
              hacia el sacerdocio, sí pensé en el servicio a los demás, por lo que consideré la Medicina como opción. Me gradué de la Universidad de Caldas, Alma Mater, entidad pública que me otorgó el título de Médico Cirujano en 2002.
            </p>
          </section>
        </swiper-slide>

        <swiper-slide class="bg-black">
          <section class="px-6 lg:px-12">
            <p>
              A mitad de la carrera de Medicina falleció mi padre, otra víctima más de la violencia en Colombia... Luego de su fallecimiento, mi cerebro empezó a considerar estudiar la psicopatología, enfermedad mental y me fui forjando la idea de
              ser Psiquiatra. Hice mi año rural en 2003 en la Clínica San Juan de Dios de Manizales, donde aprendí mucho y me decidí por la especialización, que también cursé en la misma Universidad.
            </p>
          </section>
        </swiper-slide>

        <swiper-slide class="bg-black">
          <section class="px-6 lg:px-12">
            <p>
              Fue en el 2007 cuando recibí el título de Especialista en Psiquiatría y los 10 años siguientes estuve laborando para la misma Clínica que vio nacer mis gustos por la salud mental. Paralelamente a mi trabajo clínico, empecé a incursionar
              en la docencia, la cual actualmente ejerzo en la Universidad de Manizales, en Medicina. Desde 2018 hasta la actualidad he explorado otros campos laborales de la Psiquiatría, he aprendido mucho de mis pacientes y de mis estudiantes.
            </p>
          </section>
        </swiper-slide>
      </swiper>

      <article class="flex justify-center lg:justify-end items-center pt-8 lg:py-0">
        <div class="grid grid-cols-2 relative gap-5 h-96 w-96 md:h-144 md:w-144">
          <div class="h-full w-full pl-1">
            <img class="w-full" src="@/assets/img/leoneon-picture.jpg" alt="image" />
          </div>
          <div class="h-full w-full pt-8">
            <img class="w-4/5" src="@/assets/img/home-2.jpg" alt="image" />
          </div>
          <div class="absolute w-full h-full flex justify-center items-end">
            <img class="w-2/3" src="@/assets/img/home-3.jpeg" alt="image" />
          </div>
        </div>
      </article>
    </article>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import Navbar from "@/components/Navbar.vue";

import "swiper/css";

import "swiper/css/navigation";

import { Navigation } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
    Navbar,
  },
  setup() {
    return {
      pagination: {
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + (index + 1) + "</span>";
        },
      },
      modules: [Navigation],
    };
  },
};
</script>

<style>
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-prev,
.swiper-button-next {
  color: white;
  --swiper-navigation-size: 25px;
}

.text-border{
  text-shadow: 0 0 6px white;
}
</style>
